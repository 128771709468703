import 'core-js';
import 'regenerator-runtime/runtime.js';

import smoothscroll from 'smoothscroll-polyfill';

// 为兼容以下版本浏览器，不再支持后可以删除
// < Edge 18
// < Chrome 61
smoothscroll.polyfill();

main().catch(console.error);

async function main(): Promise<void> {
  if (MOBILE) {
    // eslint-disable-next-line @mufan/import-path-strict-hierarchy
    await import(/* webpackChunkName: "mobile" */ './@mobile');
  } else {
    // eslint-disable-next-line @mufan/import-path-strict-hierarchy
    await import(/* webpackChunkName: "desktop" */ './@desktop');
  }
}
